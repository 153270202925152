@import 'app/styles/mixins'

.content
	@include content
	min-width: 1111px
	max-width: calc(100vw - 289px)

	.cover
		position: relative
		height: 235px
		margin: -18px -26px 0
		padding: 26px
		background: url("/assets/images/cloud.png") -10px
		background-size: cover
		border-top-left-radius: 16px
		border-top-right-radius: 16px
		color: $c-button-primary

		h1
			margin-top: 120px

			&:first-letter
				text-transform: capitalize

		.sun
			position: absolute
			top: 28px
			left: 160px
			width: 130px
			height: 130px
			object-fit: contain

	.green
		width: calc(100% + 52px)
		margin: -55px -26px 0

	&__item
		position: relative
		margin-top: 64px

		&_first
			margin-top: 0

		.sticker
			position: absolute
			top: -48px
			width: 96px
			object-fit: cover

			&_event
				left: 346px

			&_score
				left: 222px

			&_document
				left: 300px

			&_task
				left: 100px

		&_flex
			display: flex
			align-items: flex-end
			gap: 24px

		.block
			width: 100%

			&__caption
				display: flex
				justify-content: space-between
				align-items: center

			&__content
				margin-top: 24px
