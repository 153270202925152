@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 17px
	min-height: 100%
	padding: 24px 16px

	.navigation
		@include navigation-aside

		ul:first-child
			margin-bottom: 64px

		.link
			@include aside-navigation-link

			&_archive
				padding-left: 32px
