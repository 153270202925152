@import "app/styles/mixins"

.container
	padding: 36px
	background: white
	border-radius: 16px

	&_empty
		margin-top: 24px
		box-shadow: 0 1px 8px rgba(#225FA81C, 0.11), 0 0 3px rgba(#225FA835, 0.21)

	.stick
		width: 224px
		height: 224px
		padding: 24px
		line-height: 24px
		word-wrap: break-word

		&_pink
			background: url('/assets/images/stick-pink.png')

		&_yellow
			background: url('/assets/images/stick-yellow.png')

		&_blue
			background: url('/assets/images/stick-blue.png')

		&_green
			background: url('/assets/images/stick-green.png')

	.empty
		display: flex
		justify-content: center
		align-items: center
		height: 116px
		@include text-18
		font-weight: 500
		color: $c-gray
