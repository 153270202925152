@import 'app/styles/mixins'

.btn
	display: flex
	justify-content: center
	align-items: center
	font-weight: 500
	white-space: nowrap
	transition: 300ms

	&_primary
		background: $c-button-primary
		box-shadow: 0px 4px 24px rgba($c-button-primary, 0.4)
		color: white

		&:hover
			background: $c-button-primary-hover
			box-shadow: 0px 4px 24px rgba($c-button-primary-hover, 0.4)

	&_secondary
		background: transparent
		border: 2px solid $c-border
		color: $c-button-primary

	&_clear
		color: $c-button-primary

	&_ghost
		background: $c-button-ghost
		color: $c-button-primary

	&_l
		padding: 12px 20px
		border-radius: 12px
		font-size: 18px
		line-height: 24px

		.prefix
			margin-right: 20px

		.suffix
			margin-left: 20px

	&_m
		padding: 12px
		border-radius: 10px
		font-size: 16px

		.prefix
			margin-right: 14px

		.suffix
			margin-left: 14px

	&_s
		padding: 10px 12px
		border-radius: 8px
		font-size: 14px

		.prefix
			margin-right: 11px

		.suffix
			margin-left: 11px

	&_xs
		padding: 6px 8px
		border-radius: 6px
		font-size: 12px

		.prefix
			margin-right: 8px

		.suffix
			margin-left: 8px

	&_stretch
		width: 100%

