@import 'app/styles/mixins'

.wrapper
	display: flex
	flex-direction: column
	@include content

	.header
		display: flex
		align-items: center
		justify-content: space-between
		height: 42px
		margin-bottom: 40px

		&__controls
			display: flex
			align-items: center
			gap: 8px

	.filters
		margin-bottom: 24px

	.content
		flex: 1 1 0
		display: flex

		.empty_message
			margin: auto
			color: $c-gray
			@include text-18
