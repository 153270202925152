@import "app/styles/mixins"

.profile
	&__info
		max-width: 400px

	&__links
		display: flex
		column-gap: 8px

.form
	&__subtitle
		margin-bottom: 49px

		&:not(:first-child)
			margin-top: 40px

	&__field
		margin-bottom: 41px

		&__error
			margin-top: 4px
			color: $c-error

.child
	&__list
		display: flex
		flex-direction: column
		row-gap: 8px

	&__wrapper
		display: flex
		border: 1px solid rgba(#00000019, 0.1)
		border-radius: 4px
		padding: 8px

	&__avatar
		width: 48px
		height: 48px
		border-radius: 64px
		margin-right: 12px

	&__lastname
		letter-spacing: -0.03em
		color: $c-button-primary
		font-weight: 700
		line-height: 24px
		font-size: 20px

	&__name
		font-weight: 400
		font-size: 16px
		line-height: 19px

.loader
	margin: 100px 150px
