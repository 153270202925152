@import 'colors'
@import 'typographics'

@mixin content
	width: 100%
	min-height: calc(100vh - 115px)
	padding: 18px 26px
	background: white
	border-radius: 16px

@mixin navigation-aside
	position: sticky
	top: 91px
	align-self: flex-start
	width: 240px
	min-width: 240px

@mixin aside-navigation-link
	display: flex
	align-items: center
	width: 240px
	padding: 8px 16px
	border-radius: 34px
	font-size: 14px
	line-height: 17px
	font-weight: 500
	transition: 300ms

	&:hover
		color: $c-button-primary

	&_active
		background: white

		&:hover
			color: black

	&__icon
		margin-right: 8px
