@import 'app/styles/mixins'

.container
	display: flex
	align-items: center
	width: fit-content
	height: 40px
	padding: 2px
	border-radius: 10px
	background: $c-button-ghost

	.button
		width: auto
		padding: 8px 12px
		@include text-16

		&_active
			border-radius: 8px
			color: white

			&_primary
				background: $c-gray

			&_secondary
				background: $c-button-primary
