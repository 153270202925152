@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 17px
	min-height: 100%
	padding: 24px 16px

	.navigation
		@include navigation-aside
		display: flex
		flex-direction: column
		align-items: center

		.student
			&__avatar
				width: 140px
				height: 140px
				object-fit: cover
				border-radius: 24px

			&__name
				margin: 16px 0 8px
				font-size: 18px
				line-height: 22px
				font-weight: 700
				text-align: center

			&__birthday
				margin-bottom: 24px
				color: $c-gray
				font-weight: 500

		.link
			@include aside-navigation-link

	.classmates
		width: 100%
		margin-top: 24px
		padding: 16px
		border-radius: 16px
		border: 2px solid rgba(black, 0.10)

		&__wrapper
			display: flex
			align-items: center
			gap: 8px
			margin-top: 12px

			.avatar
				width: 44px
				height: 44px
				object-fit: cover
				border-radius: 50%

		&__count
			display: flex
			justify-content: center
			align-items: center
			width: 44px
			height: 44px
			border-radius: 50%
			color: $c-gray
			background: #d8d8dc

