@import "app/styles/mixins"

.card
	display: flex
	flex-direction: column
	align-items: center
	width: 238px
	padding: 24px 16px 32px
	background: white
	border-radius: 16px
	text-align: center

	&:hover
		outline: 3px solid #FF7122

	&_disabled
		&:hover
			outline: none
			cursor: default

	.avatar
		width: 100px
		height: 100px
		object-fit: cover
		border-radius: 24px

	.name
		margin-top: 16px

	.age
		margin-top: 10px
		color: $c-gray
		@include text-14

	.parent
		display: flex
		align-items: center
		padding: 6px
		border: 1px solid $c-border
		border-radius: 4px
		text-align: left

		&__list
			display: flex
			flex-direction: column
			gap: 4px
			width: 100%
			margin-top: 24px

		&__avatar
			width: 24px
			height: 24px
			object-fit: cover
			border-radius: 8px

		&__name
			margin-left: 4px
			@include text-14
