@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 17px
	min-height: 100%
	padding: 24px 16px

	.navigation
		@include navigation-aside

		.link
			@include aside-navigation-link

		.options
			width: 100%
			margin-top: 40px
			padding: 16px 16px 36px
			background: white
			border-radius: 16px

			h3
				margin-bottom: 20px

			&__item
				font-size: 14px
				color: black
