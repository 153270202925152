@import 'app/styles/mixins'

.container
	overflow: auto
	height: 100%

	.chat
		display: flex
		justify-content: space-between
		align-items: flex-start
		gap: 12px
		width: 100%
		min-height: 79px
		padding: 14px
		border-top: 1px solid $c-border
		transition: 300ms

		&_active
			background: #e7f3ff

		&_empty
			display: flex
			justify-content: center
			align-items: center
			width: 100%
			height: 100%
			margin-top: -40px
			font-weight: 500

		&:hover
			background: #e7f3ff

		&__avatar
			width: 44px
			height: 44px
			object-fit: cover
			border-radius: 8px

		&__info
			width: 188px
			margin-right: 12px
			@include text-14

			.name
				margin-bottom: 4px
				font-weight: 700

			.message
				max-width: 160px
				white-space: nowrap
				overflow: hidden
				text-overflow: ellipsis

		&__flags
			display: flex
			flex-direction: column
			align-items: flex-end

			.time
				margin-bottom: 4px
				@include text-14
				color: $c-gray
				text-align: right

			.circle
				width: 12px
				height: 12px
				border-radius: 50%
				background: $c-button-primary

