@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 24px
	min-height: 100%
	padding: 24px 16px

	aside
		@include navigation-aside
		height: calc(100vh - 115px)
		overflow: auto

		.tasks
			margin-bottom: 20px

		.birthdays
			margin-bottom: 20px

	.content
		@include content
