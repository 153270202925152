@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 17px
	min-height: 100%
	padding: 24px 16px

	.navigation
		@include navigation-aside

		.title
			font-size: 24px
			line-height: 29px
			font-weight: 700
			margin-left: 16px
			margin-bottom: 24px

		.link
			@include aside-navigation-link

			&_skips
				margin-top: 64px
