@import 'app/styles/mixins'

.content
	@include content
	display: flex
	justify-content: space-between
	align-items: flex-start
	gap: 62px
	padding: 0
	background: transparent
