@import 'app/styles/mixins'

.container
	display: flex
	align-items: center
	height: 100%
	border-radius: 4px
	background: $c-gray-light

	.text
		white-space: nowrap
		transform-origin: top left
		transform: rotate(90deg) translateY(-16px) translateX(-50%)
