@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 17px
	min-height: 100%
	padding: 24px 16px

	.navigation
		@include navigation-aside

		.title
			margin: 0 0 24px 16px
			font-weight: 700
			font-size: 24px
			line-height: 29px

		.link
			@include aside-navigation-link
