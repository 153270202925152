@import 'app/styles/mixins'

.wrapper
	display: flex
	height: calc(100vh - 67px)
	gap: 24px
	padding: 24px 32px

	aside
		display: flex
		flex-direction: column
		align-items: center
		width: 332px
		min-width: 332px
		background: white
		border-radius: 16px

		.filters
			margin: 8px 8px 13px

		.content
			flex: 1
			width: 100%
			overflow: auto

	.messages
		display: flex
		width: 100%
		padding: 12px 0
		border-radius: 16px
		background: white

