@import 'app/styles/mixins'

.container
	display: flex
	justify-content: space-between
	width: fit-content
	padding: 16px 0
	border-bottom: 1px solid $c-border

	.title
		width: 166px
		font-size: 12px
		line-height: 14px
		text-transform: capitalize

		&_active
			font-weight: 700
