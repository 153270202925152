@import 'app/styles/mixins'

.container
	display: flex
	align-items: center
	justify-content: center

	.cell
		width: 32px
		height: 36px
		border: 1px solid $c-border
		transition: 300ms

		&_disabled
			background-color: rgba($c-gray, 0.07)

	.tag
		&__evaluation
			width: 30px

		&__absence
			padding: 2px 4px
			color: $c-button-primary
			@include text-16

			&:hover
				border-radius: 4px
				border: 2px solid rgba(black, 0.2)

		&__empty
			width: 30px
			height: 24px

			&_active
				background: $c-border
				border-radius: 4px
