@import 'app/styles/mixins'

.container
	display: flex
	align-items: center
	justify-content: space-between

	.subject
		width: 391px

	.controls
		display: flex

		.switcher
			margin-right: 16px

		.button
			height: 40px
			border-radius: 10px 0 0 10px

			&:last-child
				border-left: 1px solid $c-border
				border-radius: 0 10px 10px 0
