@import 'app/styles/mixins'

.wrapper
	position: relative
	display: flex
	align-items: center
	padding: 12px 13px
	border: 1px solid $c-border
	border-radius: 12px
	transition: 300ms

	&:hover
		border: 1px solid #cbc3bb

	&_error
		border-color: $c-error

	input
		width: 100%
		color: black
		font-size: 18px
		line-height: 24px
		font-weight: 500

	.clear
		width: 24px
		color: rgba($c-clear, 0.4)

	.prefix
		margin-right: 12px

	.suffix
		margin-left: 12px

	.label
		position: absolute
		top: -25px
		left: 0
		color: $c-gray
		font-weight: 400
		font-size: 16px
		line-height: 19px
		letter-spacing: -0.03em
