@import 'app/styles/mixins'

.content
	flex: 1
	padding: 20px 24px
	overflow: auto
	height: 100%

	&__wrapper
		&:not(:last-child)
			margin-bottom: 16px

		.date
			display: flex
			justify-content: center
			margin-bottom: 16px

		.message
			display: flex
			align-items: flex-start
			gap: 8px

			&_author
				justify-content: flex-end

			&__avatar
				width: 30px
				height: 30px
				border-radius: 50%
				object-fit: cover

			&__wrapper
				max-width: calc(100% / 2 - 16px)
				padding: 12px
				border-radius: 12px
				background: $c-button-ghost

				&_author
					background: #e7f3ff

			&__caption
				margin-bottom: 8px
				font-weight: 700

			&__content
				display: flex
				justify-content: space-between
				align-items: flex-start
				gap: 10px

				.wrapper
					width: fit-content

					.image
						width: 100%
						object-fit: cover

				.text
					font-weight: 500

				.time
					@include text-14
					font-weight: 500
					color: $c-gray

.controls
	display: flex
	align-items: center
	gap: 16px
	margin: 0 12px
	padding: 12px
	background: $c-button-ghost
	border-radius: 50px

	&__file
		display: flex
		justify-content: center
		align-items: center
		width: 40px
		min-width: 40px
		height: 40px
		border-radius: 50%
		background: white
		cursor: pointer

	input
		width: 100%

	&__send
		display: flex
		justify-content: center
		align-items: center
		width: 40px
		min-width: 40px
		height: 40px
		border-radius: 50%
		background: $c-button-primary
		color: white
