@import "app/styles/mixins"

.container
	padding: 36px
	background: white
	border-radius: 16px

	&_empty
		box-shadow: 0 1px 8px rgba(#225FA81C, 0.11), 0 0 3px rgba(#225FA835, 0.21)

	.controls
		display: flex
		justify-content: center

	.progress_container
		position: relative
		display: flex
		align-items: center
		width: 100%
		height: 48px
		background: $c-button-ghost
		border-radius: 8px
		overflow: hidden

		&:not(:last-child)
			margin-bottom: 16px

		.content
			position: relative
			display: flex
			justify-content: space-between
			align-items: center
			width: 100%
			margin: 12px 24px
			@include text-18
			font-weight: 500

			.subject
				color: black
				z-index: 1

		.progress
			position: absolute
			display: block
			width: 0
			height: 100%
			border-radius: 8px

	.empty
		display: flex
		justify-content: center
		align-items: center
		height: 116px
		@include text-18
		font-weight: 500
		color: $c-gray
