@import 'app/styles/mixins'

.container
	overflow: auto
	height: 100%

	.contact
		display: flex
		gap: 12px
		width: 100%
		padding: 14px
		border-top: 1px solid $c-border
		transition: 300ms

		&:hover
			background: #e7f3ff

		&__avatar
			width: 44px
			height: 44px
			object-fit: cover
			border-radius: 8px

		&__info
			width: 188px
			margin-right: 12px
			@include text-14
			text-align: left

			.name
				margin-bottom: 4px
				font-weight: 700

			.label
				margin-bottom: 4px
				color: $c-gray
