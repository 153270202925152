@import 'app/styles/mixins'

.title
	margin-bottom: 16px
	font-weight: 700

.task
	width: 100%
	min-height: 100px
	padding: 16px
	background: white
	border-radius: 16px

	&__date
		display: flex
		justify-content: flex-end
		color: rgba($c-button-primary, 0.6)

	&__description
		margin: 8px 0

	&_empty
		display: flex
		justify-content: center
		align-items: center

		p
			@include text-14
			color: $c-gray

	&:not(:last-child)
		margin-bottom: 8px
