@import 'app/styles/mixins'

.content
	@include content

	h1
		font-size: 32px

.block
	margin-top: 32px

	&__header
		display: flex
		justify-content: space-between

		.date
			font-size: 24px
			font-weight: 700
			margin-bottom: 4px

		.status
			font-size: 14px
			font-weight: 700

			&_paid
				color: $c-green

			&_cancel
				color: $c-error

		.price
			margin-left: 12px
			padding-right: 20px
			font-size: 24px
			font-weight: 700

	.service
		display: flex
		justify-content: space-between
		margin-top: 12px
		padding: 16px 20px 24px
		border: 1px solid $c-border-secondary
		border-radius: 16px

		.title
			font-size: 20px
			font-weight: 700

		.description
			margin-top: 8px
			@include text-14
