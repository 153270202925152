@import 'app/styles/mixins'

.card
	width: 332px
	min-width: 332px
	padding: 16px 24px
	background: white
	border-radius: 16px

	h3
		margin-bottom: 24px
		line-height: 19px
		font-weight: 700

	.empty_message
		display: flex
		justify-content: center
		align-items: center
		height: 150px

	.lesson
		display: flex
		gap: 18px

		&_nearest
			.lesson__content
				border: 2px solid $c-error

			.lesson__time
				color: $c-error

		&:not(:last-child)
			margin-bottom: 36px

		&__time
			width: 35px
			margin-top: 10px
			font-size: 12px
			line-height: 14px
			color: $c-gray

			span
				display: inline-block

				&:first-child
					margin-bottom: 2px

		&__content
			width: 100%
			padding: 8px
			border-radius: 4px
			border: 1px solid rgba(black, 0.1)

			&__name
				margin-bottom: 3px
				font-size: 15px
				line-height: 18px
				font-weight: 700

			&__info
				font-size: 12px
				color: $c-gray

				span
					display: inline-block

					&:first-child
						margin-bottom: 2px
