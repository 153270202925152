@import 'app/styles/mixins'

.react-calendar
	border: none
	font-family: Montserrat, Arial, Helvetica, sans-serif

	&__tile
		border-radius: 8px

		&:hover
			background-color: $c-button-ghost

	&__tile
		&--active, &--hasActive
			color: white !important
			background: $c-button-primary !important

		&--now
			color: black
			background-color: $c-button-ghost

			&:enabled:hover
				background-color: $c-button-ghost

	&__navigation__arrow
		color: $c-button-primary
		background-color: transparent !important

