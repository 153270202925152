@import 'app/styles/mixins'

.form
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: calc(100% - 46px)
	margin-top: 24px

	.control
		width: 100%
		max-width: 804px

	&__row
		display: flex
		align-items: center
		gap: 16px
		margin-bottom: 24px

		.control
			width: 394px

	&__footer
		display: flex
		flex-direction: column
		align-items: flex-end

		&__controls
			display: flex
			gap: 20px

		.info
			margin-top: 8px
			color: $c-gray
			@include text-12
