@import 'app/styles/mixins'

.wrapper
	max-width: 930px
	@include content

	header
		display: flex
		justify-content: space-between
		align-items: center
		padding-bottom: 16px
		border-bottom: 1px solid $c-border-secondary

	.content
		margin-top: 16px

		&__block
			&:not(:last-child)
				margin-bottom: 24px

			.reference
				padding: 16px 16px 24px
				border: 1px solid $c-border
				border-radius: 8px

				&:not(:last-child)
					margin-bottom: 8px

				&__header
					display: flex
					align-items: center
					gap: 8px
					margin-bottom: 16px

					span
						color: $c-button-primary

				&__content
					display: flex
					justify-content: space-between
					align-items: center
					margin-left: 33px

					.info
						display: flex
						align-items: center
						gap: 56px
						color: $c-gray

						&__label
							display: block
							margin-bottom: 2px
							@include text-14

						&__content
							font-weight: 700
