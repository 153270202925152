@import 'app/styles/mixins'

.content
	display: flex
	flex-wrap: wrap
	gap: 20px

.card
	display: flex
	flex-direction: column
	width: 218px
	padding: 16px 14px
	background: white
	border-radius: 16px

	&:hover
		outline: 3px solid #FF7122

	&_my_class
		background: $c-button-primary

		.caption h3, .info p, .teacher__name
			color: white !important

		.teacher__role
			color: white !important
			opacity: 0.4

	.caption
		display: flex
		align-items: center
		margin-bottom: 18px

		&__avatar
			width: 70px
			height: 70px
			object-fit: cover
			margin-right: 16px

		h3
			font-size: 18px
			line-height: 22px
			font-weight: 700

	.my_class_label
		width: fit-content
		margin-bottom: 8px
		padding: 2px 4px
		font-size: 12px
		line-height: 14px
		font-weight: 500
		background: #e8f2ff
		color: $c-button-primary
		border-radius: 4px

	.subjects
		display: flex
		align-items: center
		flex-wrap: wrap
		gap: 4px

		&__item
			padding: 2px 4px
			font-size: 12px
			line-height: 14px
			font-weight: 500
			background: $c-border
			color: $c-gray
			border-radius: 4px

	.info
		margin: 16px 0
		text-align: left

		p
			display: flex
			align-items: center
			color: $c-gray
			font-size: 14px
			line-height: 17px
			font-weight: 500

			&:not(:last-child)
				margin-bottom: 8px

			span
				margin-left: 4px

	.teacher
		display: flex
		align-items: flex-start
		gap: 4px
		text-align: left
		font-size: 12px
		line-height: 14px
		font-weight: 500

		&:not(:last-child)
			margin-bottom: 12px

		&__avatar
			width: 24px
			height: 24px
			object-fit: cover
			border-radius: 8px

		&__name
			color: $c-button-primary

		&__role
			color: $c-gray
