@import 'app/styles/mixins'

$table-border: 1px solid $c-border

.table
	margin: 16px -26px 0
	max-width: fit-content
	overflow-x: auto
	border-top: $table-border
	border-bottom: $table-border

	.columns
		display: grid
		grid-template-columns: 278px auto 188px

	.column
		&__student
			position: sticky
			left: 0
			width: 278px
			background: white
			border-right: $table-border

		&__time
			.month
				padding: 12px 15px 3px
				border-left: $table-border

			.days
				display: flex

				.day
					display: flex
					justify-content: center
					align-items: center
					width: 32px
					height: 28px
					border-left: $table-border
					border-top: $table-border

		&__result
			position: sticky
			right: 0
			background: white
			display: flex
			justify-content: space-between
			border-left: $table-border

	.row
		&__evaluations
			display: flex

	&__head
		text-transform: uppercase
		font-weight: 600
		font-size: 12px
		line-height: 14px
		color: $c-gray

		.column
			&__result
				padding: 36px 20px 8px

			&__student
				padding: 12px 24px

	&__body
		.column
			&__result
				padding: 7px 34px 5px
				border-left: $table-border

			&__student
				display: flex
				align-items: center
				padding: 2px 12px 7px
				height: 36px
				border-top: $table-border
				@include text-16

				.avatar
					width: 24px
					height: 24px
					border-radius: 8px
					object-fit: cover
					margin-right: 8px
