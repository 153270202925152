@import 'app/styles/mixins'

.label
	display: inline-block
	margin-bottom: 6px
	line-height: 19px
	color: $c-gray

	&_m
		margin-left: 16px

.control
	&_error
		border-color: $c-error !important

	&_s
		font-size: 12px
		height: 22px

	&_m
		font-size: 18px
		height: 48px

.option
	&_s
		font-size: 12px
	&_m
		font-size: 18px
