@import 'app/styles/mixins'

.content
	display: flex
	gap: 86px
	margin-top: 24px

	.info
		width: 100%
		max-width: 400px

		&__block
			margin-top: 24px

			.item
				margin-bottom: 16px

				&:last-child
					margin-bottom: 40px

				&__label
					margin-bottom: 6px
					color: $c-gray

				&__data
					@include text-18
					color: $c-clear

	.avatar
		width: 200px
		height: 200px
		margin-top: 24px
		object-fit: cover
		border-radius: 24px
