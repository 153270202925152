@import 'app/styles/mixins'

.content
	position: relative
	@include content
	padding: 32px
	min-width: 0

	.close
		position: absolute
		right: 16px
		top: 16px

	.title
		font-size: 32px
		line-height: 38px
		font-weight: 700
		margin-bottom: 24px

	.subtitle
		margin-bottom: 24px

		&:not(:first-child)
			margin-top: 40px

	.employee
		display: flex

		.avatar
			width: 200px
			height: 200px
			border-radius: 24px
			object-fit: cover

		&__data
			width: 486px

			span
				font-size: 16px
				line-height: 19px
				color: $c-gray

			p
				margin: 6px 0 16px
				color: $c-clear
				line-height: 27px
				@include text-18

	.documents
		&__header
			margin: 48px 0 24px
			display: flex
			justify-content: space-between

			.link
				line-height: 24px
				color: $c-button-primary

		&__item
			width: 100%
