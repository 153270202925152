@import 'app/styles/mixins'

.navigation
	ul
		display: flex
		align-items: center

		.link
			margin-right: 8px
			padding: 10px 16px
			font-size: 16px
			font-weight: 500
			border-radius: 30px
			white-space: nowrap
			transition: 300ms

			&:hover
				color: $c-button-primary

			&_active
				color: white
				background: $c-button-primary

				&:hover
					color: white
