@import 'app/styles/mixins'

.table
	width: 100%

	.grid
		display: grid
		grid-template-columns: 40px auto 134px

	&__header
		align-items: center
		height: 40px
		padding: 0 	25px 9px 20px
		border-bottom: 1px solid $c-border
		font-size: 12px
		line-height: 14px

	ul
		display: flex
		flex-direction: column
		row-gap: 4px
		margin-top: 24px

		li
			padding: 10px 20px
			font-size: 16px
			line-height: 22px

	&__block
		padding: 24px 0
		border-bottom: 2px solid $c-border

		&:nth-child(2)
			padding-top: 20px

		&__title
			@include text-20

	.deadline
		display: flex
		flex-direction: column
		row-gap: 8px

		&_centred
			text-align: center

	.dates
		display: flex
		justify-content: space-between

