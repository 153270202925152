@import 'reset'
@import 'typographics'
@import 'abstracts/variables'
@import 'theme/calendar'
@import 'theme/react-modal'
@import 'react-calendar/dist/Calendar.css'
@import 'react-toastify/dist/ReactToastify.min.css'
@import 'react-tooltip/dist/react-tooltip.css'
@import 'swiper/css'
@import 'theme/toast'

html,
body
	width: 100%
	height: 100%

html
	font-family: $montserrat
	line-height: 1
	font-size: 16px

body
	display: flex
	justify-content: center

#root
	height: 100%
	width: 100%
