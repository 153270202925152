@import "app/styles/mixins"

.wrapper
	display: flex
	flex-direction: column
	width: 100%

	.label
		margin-bottom: 6px
		color: $c-gray

		&_s
			font-size: 14px

		&_m
			font-size: 16px

	.textarea
		height: 100%
		resize: none
		padding: 5px 7px
		border: 1px solid $c-border
		border-radius: 4px
		transition: 300ms

		&_error
			border-color: $c-error

		&:hover
			border: 1px solid #cbc3bb

		&_s
			line-height: 18px
			@include text-12
		&_m
			line-height: 27px
			@include text-18
