@import 'app/styles/mixins'

.container
	padding: 16px
	overflow: auto

	.navigation
		display: block
		margin-bottom: 12px
		padding: 24px 16px
		border: 1px solid rgba(black, 0.1)
		border-radius: 4px
		transition: 300ms

		&:hover
			box-shadow: 0 4px 8px rgba(black, 0.06)

		&__class_name
			display: inline-block
			margin-bottom: 4px
			font-size: 18px
			line-height: 22px
			font-weight: 700

		&__label
			display: inline-block
			line-height: 19px
			font-weight: 500
			color: $c-button-primary

	.student
		display: flex
		align-items: flex-start
		gap: 12px
		padding: 8px
		border: 1px solid rgba(black, 0.1)
		border-radius: 4px

		&:not(:last-child)
			margin-bottom: 8px

		&__avatar
			width: 48px
			height: 48px
			object-fit: cover
			border-radius: 50%

		&__last_name
			display: block
			margin-bottom: 2px
			color: $c-button-primary
			font-size: 20px
			font-weight: 700

		&__name
			display: block
			font-size: 16px
			line-height: 19px
