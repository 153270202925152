@import "app/styles/mixins"

.card
	min-height: 168px
	padding: 24px
	background: white
	border-radius: 16px

	&_empty
		box-shadow: 0 1px 8px rgba(#225FA81C, 0.11), 0 0 3px rgba(#225FA835, 0.21)

	&__content
		margin-top: 48px

		.info
			display: flex
			align-items: center
			gap: 8px
			font-weight: 500

			&:not(:last-child)
				margin-bottom: 12px

	.empty
		display: flex
		justify-content: center
		align-items: center
		height: 116px
		@include text-18
		font-weight: 500
		color: $c-gray
