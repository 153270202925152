@import 'app/styles/mixins'

.form
	margin-top: 32px

	&__field
		&:first-child
			margin-bottom: 16px

		&__error
			margin-top: 4px
			margin-left: 12px
			font-size: 12px
			color: $c-error

	&__control
		margin-top: 24px

		&_link
			margin-top: 24px
			font-size: 18px
			line-height: 22px
			font-weight: 500

	.auth_error
		margin-top: 12px
		text-align: center
		font-size: 12px
		color: $c-error
