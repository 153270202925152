@import 'app/styles/mixins'

.form
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: calc(100% - 70px)
	margin-top: 48px

	.control
		margin-bottom: 24px

		&_datepicker
			width: 400px

		&_stretch
			width: 100%

	&__footer
		display: flex
		flex-direction: column
		align-items: flex-end

		&__controls
			display: flex
			gap: 20px
