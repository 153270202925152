@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 17px
	min-height: 100%
	padding: 24px 16px

	.navigation
		.link
			@include aside-navigation-link

	.content
		@include content
		max-width: 772px

		.title
			padding: 32px 0 16px 32px
			margin: -18px -26px 0
			border-bottom: 1px solid $c-border-secondary

		.button
			&_add
				margin-top: 24px

.notification
	margin-top: 24px

	&_empty
		display: flex
		justify-content: center
		align-items: center
		min-height: calc(100vh - 174px)

		p
			@include text-14
			color: $c-gray

	&__date
		margin-bottom: 16px
		font-size: 20px
		font-weight: 700

	.event
		display: flex
		margin-top: 8px
		padding: 16px
		outline: 1px solid $c-border
		border-radius: 6px
		cursor: pointer
		@include text-16

		&_active
			outline: none
			background: #e8F3ff

		&__text
			margin-left: 32px
