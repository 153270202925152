@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: flex-start
	gap: 17px
	min-height: 100%
	padding: 24px 16px

	.navigation
		@include navigation-aside
		display: flex
		flex-direction: column
		align-items: center

		.student
			&__avatar
				width: 140px
				height: 140px
				object-fit: cover
				border-radius: 24px

			&__name
				margin: 16px 0 8px
				font-size: 18px
				line-height: 22px
				font-weight: 700
				text-align: center

			&__birthday
				color: $c-gray
				font-weight: 500

		nav
			margin-top: 12px

		.link
			@include aside-navigation-link

			.caption
				display: flex
				justify-content: space-between
				align-items: center
				gap: 62px
				width: 100%

				.count
					display: flex
					justify-content: center
					align-items: center
					padding: 2px
					min-width: 20px
					height: 20px
					background: $c-button-primary
					color: white
					font-size: 14px
					border-radius: 50%

	.parents
		width: 100%
		margin-top: 24px
		padding: 16px
		border-radius: 16px
		border: 2px solid rgba(black, 0.10)

		&__item
			display: flex
			align-items: center
			gap: 8px
			width: 100%
			margin-top: 12px
			font-size: 14px
			line-height: 17px

			.avatar
				width: 32px
				height: 32px
				object-fit: cover
				border-radius: 50%

	.classmates
		width: 100%
		margin-top: 24px
		padding: 16px
		border-radius: 16px
		border: 2px solid rgba(black, 0.10)

		&__wrapper
			display: flex
			align-items: center
			gap: 8px
			margin-top: 12px

			.avatar
				width: 44px
				height: 44px
				object-fit: cover
				border-radius: 50%

		&__count
			display: flex
			justify-content: center
			align-items: center
			width: 44px
			height: 44px
			border-radius: 50%
			color: $c-gray
			background: #d8d8dc

	.birthdays
		position: relative
		margin-top: 24px

		.sticker
			position: absolute
			top: 5px
			right: 5px
			width: 64px
			object-fit: cover
