@import 'app/styles/mixins'

.container
	width: 100%

	.card
		&__list
			width: 100%
			display: grid
			grid-template-columns: repeat(2, minmax(0, 1fr))
			gap: 20px

		&__container
			display: flex
			text-align: left
			padding: 24px
			border-radius: 16px
			background: white

			.avatar
				width: 100px
				height: 100px
				object-fit: cover
				border-radius: 24px
				margin-right: 16px

			.name
				font-weight: 700
				font-size: 20px
				line-height: 24px

			.department
				margin-top: 8px
				color: $c-gray
				@include text-14

	.button
		&_add
			margin-top: 20px
