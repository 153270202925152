@import 'app/styles/mixins'

.content
	display: flex
	flex-direction: column
	text-align: left
	color: #fafafa

	.switcher
		display: flex
		column-gap: 4px

	.field
		&__title
			@include text-12

			&:not(:first-child)
				margin-top: 16px

		&__value
			margin-top: 4px
			font-size: 14px
			line-height: 16px
			font-weight: 700
