@import "app/styles/mixins"

.card
	display: flex
	flex-direction: column
	align-items: flex-start
	justify-content: space-between
	width: 320px
	padding: 16px 16px 36px
	border-radius: 16px
	background-color: white
	text-align: left

	.name
		margin-bottom: 4px
		font-size: 20px
		line-height: 24px
		font-weight: 700

	.text
		margin-top: 4px
		color: $c-gray
		@include text-14

	.student
		display: flex
		flex-direction: column
		margin-top: 16px

		&__name
			margin-top: 2px
			color: $c-gray
