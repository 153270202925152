@import 'app/styles/mixins'

.wrapper
	padding: 24px 16px

	.content
		@include content

		.blocks
			display: flex
			column-gap: 24px
			margin-top: 32px

.cards
	display: flex
	flex-direction: column
	row-gap: 24px
	width: 100%

	.card
		display: flex
		width: 100%
		padding: 16px
		border: 1px solid $c-border-secondary
		border-radius: 16px

		.data
			max-width: 632px
			margin-left: 26px

			&__description
				margin-top: 8px

		.delete
			width: 32px
			height: 32px
			padding: 4px
			background: $c-button-ghost
			color: $c-button-primary
			border-radius: 8px
			transition: 300ms

			&:hover
				background: $c-button-primary
				color: $c-button-ghost

		.price
			margin-left: auto

.aside
	width: 332px
	height: fit-content
	padding: 32px
	background: $c-button-ghost
	border-radius: 16px

	&__title
		@include text-14

	.order
		margin-top: 32px

	.price
		margin-top: 8px
		font-size: 32px
		font-weight: 700
