@import 'app/styles/mixins'

.header
	position: sticky
	top: 0
	display: flex
	justify-content: space-between
	align-items: center
	padding: 12px 24px
	border-bottom: 3px solid $c-border
	background: linear-gradient(90deg, #ffeeee 0%, #ddefbb 100%)
	z-index: 2

	.navigation
		display: flex
		align-items: center
		gap: 93px

	.controls
		display: flex
		align-items: center
		gap: 24px

		.children
			display: flex
			align-items: center
			gap: 12px

			&__item
				width: 32px
				height: 32px
				object-fit: cover
				border-radius: 8px
				opacity: 0.6
				transition: 300ms

				&:hover
					opacity: 1

				&_active
					opacity: 1

		&__group
			display: flex
			gap: 12px

		.link
			position: relative
			padding: 4px
			border-radius: 8px
			background: white
			color: $c-button-primary
			transition: 300ms

			&:hover
				background: $c-button-primary
				color: white

			&_active
				background: $c-button-primary
				color: white

			.messages
				position: absolute
				top: -10px
				right: -10px
				display: flex
				justify-content: center
				align-items: center
				min-width: 20px
				min-height: 20px
				padding: 3px
				font-size: 10px
				line-height: 14px
				background: $c-button-primary
				color: white
				border-radius: 50%
