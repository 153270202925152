@import 'app/styles/mixins'

.container
	min-height: 168px
	padding: 20px
	border-radius: 16px
	background: white
	margin: 1px
	box-shadow: 0 1px 8px rgba(#225FA81C, 0.11), 0 0 3px rgba(#225FA835, 0.21)

	&:hover
		background: #F6F9FC

	.header
		display: flex
		justify-content: space-between

		.avatar
			width: 64px
			height: 64px
			object-fit: cover

	.title
		margin-top: 24px
		font-weight: 700
		font-size: 16px
		line-height: 19px
		text-align: left

	.label
		margin: 16px 0 4px
		opacity: 0.6
		color: $c-gray
		@include text-12

	span
		color: $c-gray
		@include text-14

	.empty
		display: flex
		justify-content: center
		align-items: center
		height: 116px
		@include text-18
		font-weight: 500
		color: $c-gray
