@import 'app/styles/mixins'

.container
	display: flex
	justify-content: space-between

	.link
		padding: 12px 16px
		border-radius: 10px
		border: 1px solid $c-gray
		@include text-16
