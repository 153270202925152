@import 'app/styles/mixins'

.content
	@include content

	h2
		margin-bottom: 24px

	.block
		min-height: 140px
		margin-top: 8px
		padding: 22px 16px
		border-radius: 8px
		background: $c-button-ghost

		&__header
			display: flex
			justify-content: space-between
			align-items: center
			margin-bottom: 16px

			.caption
				display: flex
				align-items: center
				gap: 4px

			.count
				display: flex
				justify-content: center
				align-items: center
				padding: 2px
				min-width: 20px
				height: 20px
				background: $c-button-primary
				color: white
				font-size: 14px
				border-radius: 50%

		&__empty_message
			@include text-14
			color: $c-gray

		.tasks
			display: flex
			gap: 16px
			flex-wrap: wrap

			&__item
				display: flex
				flex-direction: column
				justify-content: space-between
				min-width: calc(100% / 3 - 11px)
				padding: 16px
				box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06)
				border-radius: 6px

				&_home_work
					background: #f0f7ff
					border: 1px solid $c-button-primary

				&_personal_work
					background: #f6fffc
					border: 1px solid #16a880

				&_control_work
					background: #ffefe8
					border: 1px solid #ff7122

				&_personal_studying
					background: #fffaf2
					border: 1px solid #ffa922

				&_other
					background: #f6f4ff
					border: 1px solid #5236ff

				&__caption
					margin-bottom: 12px
					font-size: 14px
					font-weight: 500

				&__info
					display: flex
					align-items: center
					gap: 4px
					flex-wrap: wrap

					&__item
						padding: 2px 6px
						background: $c-border
						color: $c-gray
						border-radius: 4px
						font-size: 14px
						line-height: 17px
						font-weight: 500

				&__controls
					display: flex
					justify-content: space-between
					align-items: center
					margin-top: 12px

				.avatar
					width: 24px
					height: 24px
					object-fit: cover
					border-radius: 50%

				.author
					display: flex
					align-items: center
					gap: 8px
					@include text-14

		.more
			margin-top: 16px
