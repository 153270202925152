@import 'app/styles/mixins'

.form
	display: flex
	gap: 40px

.select
	&_short
		width: 160px
		min-width: 160px

	&_long
		width: 100%
