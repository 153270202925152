@import "app/styles/mixins"

.wrapper
	position: relative

	.navigation
		color: $c-button-primary

		&__next
			transform: rotate(180deg)

		&__container
			display: flex
			justify-content: flex-end
			gap: 8px
			margin-bottom: 16px

.pagination
	position: relative !important
	display: flex
	flex-direction: row
	justify-content: center
	column-gap: 4px
	margin-top: 32px

	.dot
		display: block
		height: 8px
		width: 8px
		border-radius: 50%
		background: #D8D8DC

		&_active
			background: $c-button-primary
