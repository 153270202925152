@import "app/styles/mixins"

.wrapper
	display: flex
	align-items: flex-start
	width: 100%

	.content
		width: 100%
		min-height: calc(100vh - 115px)
		padding: 32px 24px
		background: white

		.header
			display: flex
			align-items: center
			column-gap: 8px

			&__button
				display: flex
				justify-content: center
				align-items: center
				width: 38px
				height: 38px
				border: 2px solid #e6e6e6
				border-radius: 8px

			&_wrapper
				display: flex
				justify-content: space-between
				align-items: center

		.theme
			margin-top: 8px
			@include text-14

		.cards
			display: flex
			flex-wrap: wrap
			gap: 10px
			margin-top: 24px

			.file
				display: flex
				align-items: flex-start
				gap: 8px

			.cover
				display: flex
				justify-content: center
				align-items: center
				width: 131px
				height: 98px
				background-repeat: no-repeat
				background-size: cover
				background-position: center
				border-radius: 8px

				.play
					padding: 6px 8px
					background: white
					border-radius: 8px

			img
				width: 131px
				height: 98px
				object-fit: cover
				border-radius: 8px

			.upload
				display: flex
				flex-direction: column
				justify-content: center
				align-items: center
				width: 131px
				height: 98px
				border-radius: 8px
				border: 1px dashed #d9d9d9
				color: #4b4a49
				@include text-12

				&:hover
					cursor: pointer

	.close
		padding: 11px
		border-top-right-radius: 10px
		border-bottom-right-radius: 10px
		background: white
		color: $c-button-primary

