.ReactModal__Overlay
	transform: translateX(100%)
	transition: transform 0.3s ease-in-out
	z-index: 100

.ReactModal__Overlay--after-open
	transform: translateX(0%)

.ReactModal__Overlay--before-close
	transform: translateX(100%)

.ReactModal
	&__Body--open
		position: fixed
		overflow: hidden
