@import 'app/styles/mixins'

.wrapper
	padding: 20px 16px
	background: white
	border-radius: 16px

	h3
		line-height: 19px
		font-weight: 700

	.date
		margin-top: 9px

	.empty
		margin: 24px 0
		color: $c-gray
		@include text-14

	.students
		margin-top: 24px

		&__item
			display: flex
			justify-content: space-between
			align-items: center

			&:not(:last-child)
				margin-bottom: 14px

			img
				width: 32px
				height: 32px
				border-radius: 50%
				object-fit: cover

			.info
				margin: 0 8px

				&__line
					display: block
					font-size: 12px
					line-height: 14px
					font-weight: 500

					&:first-child
						margin-bottom: 2px

					&_class
						opacity: 0.5

			.age
				min-width: fit-content
				font-size: 14px
				line-height: 17px
				font-weight: 700

	.control
		margin-top: 16px
