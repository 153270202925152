@import "app/styles/mixins"

.avatar
	display: flex
	flex-direction: column
	align-items: flex-start
	margin-bottom: 24px

	&__photo
		width: 200px
		height: 200px
		object-fit: cover
		margin-bottom: 16px
		border-radius: 24px

	&__subtitle
		margin-bottom: 24px

.btn
	border-radius: 8px
	background: $c-button-ghost
	cursor: pointer
	@include text-16

	&__delete
		margin-top: 16px
		padding: 7px 24px 6px 14px
		color: #BF1212

	&__upload
		padding: 7px 14px

input[type='file']
	display: none
