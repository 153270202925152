@import 'app/styles/mixins'

.container
	height: calc(100% - 54px)
	overflow: auto

	&_contacts
		height: calc(100% - 221px)

.tooltip
	position: absolute
	top: 0
	left: 0
	display: flex
	justify-content: space-between
	width: 100%
	min-width: 1400px
	padding: 12px 24px
	background: $c-button-primary
	box-shadow: 0px 9px 24px rgba(black, 0.25)
	color: white
	z-index: 2

	.button
		&_clear
			color: white

		&_finish
			width: 200px
			background: $c-button-ghost

	&__caption
		display: flex
		align-items: center
		gap: 62px

		p
			font-weight: 700

.header
	display: flex
	flex-direction: column
	padding: 0 16px 16px

	.button
		align-self: center

	.filters
		margin-top: 20px

		&__item
			margin-top: 20px

.chat
	display: flex
	justify-content: space-between
	align-items: flex-start
	gap: 12px
	width: 100%
	min-height: 79px
	padding: 14px
	border-top: 1px solid $c-border
	transition: 300ms

	&_active
		background: #e7f3ff

	&_empty
		display: flex
		justify-content: center
		align-items: center
		width: 100%
		height: 100%
		margin-top: -20px
		font-weight: 500

	&:hover
		background: #e7f3ff

	&_without_hover
		&:hover
			background: none

	&__avatar
		width: 44px
		height: 44px
		object-fit: cover
		border-radius: 8px

	&__info
		width: 188px
		margin-right: 12px
		@include text-14

		.name
			margin-bottom: 4px
			font-weight: 700

		.label
			margin-bottom: 4px
			color: $c-gray

		.message
			max-width: 160px
			white-space: nowrap
			overflow: hidden
			text-overflow: ellipsis

	&__flags
		display: flex
		flex-direction: column
		align-items: flex-end

		.time
			margin-bottom: 4px
			@include text-14
			color: $c-gray
			text-align: right

		.circle
			width: 12px
			height: 12px
			border-radius: 50%
			background: $c-button-primary

.group
	display: flex
	flex-direction: column
	align-items: center
	padding: 0 16px

	&__avatar
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		width: 200px
		height: 200px
		background: rgba(#d9d9d9, 0.5)
		border-radius: 24px
		@include text-12
		color: $c-gray
		cursor: pointer

		p
			margin-top: 10px
			text-align: center

	&__cover
		width: 200px
		height: 200px
		border-radius: 24px
		object-fit: cover

	&__theme
		width: 100%
		height: 36px
		margin-top: 20px
		@include text-12

