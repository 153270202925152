.wrapper
  min-height: 100%
  display: grid
  grid-template: 100vh / 1fr 1fr
  background-color: #F4F4F4

.leftSide
  position: relative

.rightSide
  background-image: url("../../../../assets/images/auth-background.svg")
  background-size: cover

.form
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%

.logoIcon
  position: absolute
  top: 40px
  left: 40px
