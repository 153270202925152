@import 'app/styles/mixins'

.wrapper
	display: flex
	align-items: center

	.user
		margin-right: 16px
		text-align: right

		span
			display: block

		&__name
			font-size: 14px
			line-height: 17px
			font-weight: 500

		&__role
			font-size: 12px
			line-height: 14px
			font-weight: 500
			color: $c-gray

	.info
		display: flex
		align-items: center

		img
			width: 40px
			height: 40px
			border-radius: 50%
			object-fit: cover

		.arrow
			margin-left: 4px
			color: $c-button-primary
