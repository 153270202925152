@import 'app/styles/mixins'

.container
	position: relative
	display: flex
	height: 100vh

	.content
		width: 100%
		padding: 24px
		background: white
		overflow: auto

.close
	position: absolute
	top: 16px
	right: 16px
	height: 40px
	width: 40px
	padding: 8px
	color: $c-button-primary
	background: white
	border-radius: 10px 0px 0px 10px
