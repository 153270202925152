@import 'app/styles/mixins'

.wrapper
	display: flex
	flex-direction: column
	width: 100%

	.header
		display: flex
		justify-content: space-between
		align-items: center
		padding: 0 24px 12px
		border-bottom: 1px solid $c-border

		.user
			display: flex
			gap: 12px

			&__avatar
				width: 48px
				height: 48px
				object-fit: cover
				border-radius: 8px

			&__name
				@include text-20
				font-weight: 700
				color: $c-button-primary

		.files
			display: flex
			justify-content: center
			align-items: center
			width: 32px
			min-width: 32px
			height: 32px
			background: $c-button-ghost
			color: $c-button-primary
			border-radius: 8px

	.content
		flex: 1
		padding: 20px 24px

	.controls
		display: flex
		align-items: center
		gap: 16px
		margin: 0 12px
		padding: 12px
		background: $c-button-ghost
		border-radius: 50px

		&__file
			display: flex
			justify-content: center
			align-items: center
			width: 40px
			min-width: 40px
			height: 40px
			border-radius: 50%
			background: white

		input
			width: 100%

		&__send
			display: flex
			justify-content: center
			align-items: center
			width: 40px
			min-width: 40px
			height: 40px
			border-radius: 50%
			background: $c-button-primary
			color: white
