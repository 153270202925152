@import 'app/styles/mixins'

.wrapper
	margin: 64px 0

.block
	margin-top: 64px

	&__title
		display: flex
		align-items: center
		gap: 16px

		.field
			width: 100%
			max-width: 804px

	&__control
		margin-top: 24px

	.lesson
		display: flex
		column-gap: 16px
		height: 162px
		margin-top: 24px

		&__title
			max-width: 804px

		&__dates
			display: flex
			flex-direction: column
			justify-content: space-between
			margin-top: 22px

			.datepicker
				margin-top: 24px

	.remove
		color: $c-button-primary

		&:disabled
			cursor: not-allowed
			color: gray
