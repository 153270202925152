@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap")

h1
	font-size: 24px
	line-height: 29px
	font-weight: 700

h2
	font-size: 20px
	line-height: 24px
	font-weight: 700

h3
	font-size: 18px
	line-height: 22px
	font-weight: 700

h4
	font-size: 16px
	line-height: 19px
	font-weight: 700

h5
	font-size: 14px
	line-height: 16px
	font-weight: 700

@mixin text-10
	font-size: 10px
	font-weight: 500

@mixin text-12
	font-size: 12px
	font-weight: 500

@mixin text-13
	font-size: 13px
	line-height: 16px
	font-weight: 500

@mixin text-14
	font-size: 14px
	line-height: 17px
	font-weight: 500

@mixin text-15
	font-size: 15px
	line-height: 18px
	font-weight: 700

@mixin text-16
	font-size: 16px
	font-weight: 500

@mixin text-18
	font-size: 18px
	font-weight: 500

@mixin text-20
	font-size: 20px
	font-weight: 500
