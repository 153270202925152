@import 'app/styles/mixins'

$table-border: 1px solid $c-border

.table
	margin: 17px -26px 0

	&__head
		display: grid
		grid-template-columns: 246px auto 114px 84px 85px
		border-top: $table-border

	&__body
		display: grid
		grid-template-columns: 246px auto 25px 63px 25px 85px 85px
		border-top: $table-border

	.column
		&__head
			padding: 12px 0
			font-weight: 600
			font-size: 12px
			line-height: 14px
			text-transform: uppercase
			color: $c-gray

			&:first-child
				margin-left: 24px

			&:nth-child(2)
				margin-left: 16px

			&:nth-child(-n + 3)
				border-right: $table-border

			&:nth-child(n + 3)
				text-align: center

	.cell
		&__subject
			padding: 9px 24px
			line-height: 19px
			border-right: $table-border
			border-bottom: $table-border
			@include text-16

		&__evaluations
			display: flex
			flex-wrap: wrap
			gap: 2px
			min-height: 36px
			padding: 6px 15px
			border-bottom: $table-border
			border-right: $table-border

		&__skip
			display: flex
			align-items: center
			justify-content: center
			line-height: 19px
			color: $c-gray
			border-bottom: $table-border
			@include text-16

			hr
				margin: 0 8px
				width: 1px
				height: 19px
				opacity: 0.3
				background-color: $c-gray

		&__result
			display: flex
			justify-content: center
			align-items: center
			border-bottom: $table-border
			border-left: $table-border

	.alert
		padding: 4px
		border-bottom: 1px solid $c-border
