@import 'app/styles/mixins'

.wrapper
	position: relative
	display: flex
	justify-content: space-between
	align-items: center
	min-width: 180px
	padding: 12px 13px
	border: 1px solid $c-border
	border-radius: 12px
	transition: 300ms

	&:hover
		border: 1px solid #cbc3bb

	&_error
		border-color: $c-error

	.content
		display: flex
		align-items: center
		font-size: 18px
		line-height: 24px

		&__placeholder
			color: rgba($c-clear, 0.4)
			white-space: nowrap

	.clear
		color: rgba($c-clear, 0.4)

	.prefix
		margin-right: 12px
		color: rgba($c-clear, 0.4)

	.calendar
		position: absolute
		right: -1px
		min-width: 150px
		padding: 16px
		border-radius: 16px
		text-align: center
		background: white
		box-shadow: 0px 14px 64px rgba(black, 0.04)
		font-size: 14px
		line-height: 17px
		font-weight: 500
		z-index: 2

		&__container
			position: relative
			margin-left: 24px

		.button
			height: 40px

	.label
		position: absolute
		top: -25px
		left: 0
		color: $c-gray
		font-weight: 400
		font-size: 16px
		line-height: 19px
		letter-spacing: -0.03em
