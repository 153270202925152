@import 'app/styles/mixins'

.wrapper
	display: flex
	gap: 17px
	padding: 24px 16px

.aside
	@include navigation-aside

	&__header
		display: flex
		flex-direction: column
		align-items: center

	.avatar
		width: 140px
		height: 140px
		object-fit: cover
		border-radius: 24px

	.full_name
		margin-top: 16px
		line-height: 38px

	.navigation
		margin-top: 24px

		.link
			@include aside-navigation-link

			&:last-child
				margin-top: 40px
