.content
	margin: 50px auto 0
	max-width: 660px

	.media
		width: 100%
		height: 390px
		object-fit: cover

	.controls
		display: flex
		justify-content: flex-end
		column-gap: 16px
		margin-top: 24px
