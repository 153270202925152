$c-button-primary: #225fa8
$c-button-primary-hover: #4c7db9
$c-button-ghost: #f4f4f4
$c-error: #ff7122
$c-red: #f5501f
$c-yellow: #ffa922
$c-green: #16a880
$c-tone: #1e1e1ee5
$c-border: #e6e6e6
$c-border-secondary: #c4c4c4
$c-gray-light: #f2f2f2
$c-gray: #777777
$c-clear: #4B4A49
