@import 'app/styles/mixins'

.wrapper
	padding: 24px 16px

	.content
		@include content

.cards
	display: flex
	flex-wrap: wrap
	gap: 24px

	p
		@include text-14

	.card
		display: flex
		flex-direction: column
		justify-content: space-between
		width: 310px
		border: 1px solid $c-border-secondary
		border-radius: 8px
		overflow: hidden

		&__content
			padding: 32px 24px

		.description
			margin-top: 12px

		.price
			margin-top: 24px
			opacity: 0.5

			&__value
				font-size: 24px
				font-weight: 700

		.add
			width: 100%
			padding: 14px 0 17px
			font-weight: 700
			font-size: 14px
			border-top: 1px solid $c-border-secondary
			color: $c-button-primary
			transition: 300ms

			&:hover
				background: $c-button-primary
				color: white
