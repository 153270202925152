@import 'app/styles/mixins'

.content
	@include content
	padding: 32px
	min-width: 0

	.title
		font-size: 32px
		line-height: 38px
		font-weight: 700

	.form
		display: flex
		gap: 86px
		margin-top: 24px

	&__item
		margin-top: 64px

		.flex_header
			display: flex
			align-items: center
			gap: 16px
			margin-top: 16px

		.file_control
			padding: 10px 12px
			border-radius: 8px
			font-size: 14px
			background: $c-button-primary
			box-shadow: 0px 4px 24px rgba($c-button-primary, 0.4)
			color: white
			cursor: pointer

			&:hover
				background: $c-button-primary-hover
				box-shadow: 0px 4px 24px rgba($c-button-primary-hover, 0.4)

			input
				width: 100%

	.documents
		margin-top: 24px

		&__item
			width: 100%
