@import 'app/styles/mixins'

.form
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: calc(100% - 70px)
	margin-top: 48px

	.preview
		width: 394px
		height: 394px
		object-fit: cover
		object-position: center
		border-radius: 8px

		&_container
			display: flex
			align-items: flex-start
			gap: 8px

		&__label
			margin-bottom: 16px
			font-size: 16px
			line-height: 19px
			font-weight: 500
			color: #14254a

	.upload
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		width: 394px
		height: 394px
		border-radius: 8px
		border: 1px dashed #d9d9d9
		color: #4b4a49
		@include text-18

		&:hover
			cursor: pointer

	.control
		max-width: 800px
		margin-bottom: 48px

		&_datepicker
			width: 400px

	&__footer
		display: flex
		flex-direction: column
		align-items: flex-end

		&__controls
			display: flex
			gap: 20px
