@import 'app/styles/mixins'

.wrapper
	display: flex
	flex-direction: column
	min-height: 240px
	padding: 20px 16px
	background: white
	border-radius: 16px

	.empty_message
		margin: auto
		color: $c-gray
		@include text-14

	.event
		margin-top: 12px
		padding: 8px
		border: 1px solid rgba(black, 0.1)
		border-radius: 4px

		&:first-child
			margin-top: 16px

		&__caption
			@include text-15
			color: #994d4d

		&__members
			@include text-12
			margin: 4px 0
			line-height: 14px
			color: rgba(#994d4d, 0.8)

		&__time
			margin-bottom: 2px
			font-size: 12px
			color: $c-gray

		&__place
			font-size: 12px
			color: $c-gray
