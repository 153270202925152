@import 'app/styles/mixins'

.header
	display: flex
	justify-content: space-between
	align-items: center
	margin: 0 -26px
	padding: 6px 26px 24px
	border-bottom: 1px solid $c-border-secondary

	&__title
		display: flex
		align-items: center

		h1
			font-size: 32px
			line-height: 38px

		.calendar
			position: absolute
			top: 50px
			right: 0
			min-width: 150px
			padding: 16px
			border-radius: 16px
			text-align: center
			background: white
			box-shadow: 0px 14px 64px rgba(black, 0.25)
			font-size: 14px
			line-height: 17px
			font-weight: 500
			z-index: 2

			&__container
				position: relative
				margin-left: 24px

			.button
				height: 40px

	&__controls
		display: flex
		align-items: center

		.switcher
			margin-right: 42px

		.button
			height: 40px
			border-radius: 10px 0 0 10px

			&:last-child
				border-left: 1px solid $c-border
				border-radius: 0 10px 10px 0

.days
	margin-bottom: 16px

.content
	display: flex
	align-items: flex-start

	&__column
		width: 150px

		&:not(:last-child)
			margin-right: 16px

		.event
			padding: 10px 8px
			border: 1px solid rgba(black, 0.1)
			border-radius: 4px

			&:not(:last-child)
				margin-bottom: 8px

			&__time
				margin-bottom: 6px
				@include text-12
				line-height: 14px
				color: $c-button-primary

			&__name
				@include text-15

			&__info
				margin-top: 4px
				@include text-12
				color: $c-gray

			&__school
				margin-top: 4px
				@include text-10
				color: $c-button-primary

			&__teacher
				margin-top: 8px
				@include text-12
