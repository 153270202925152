@import 'app/styles/mixins'

.form
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: calc(100% - 70px)
	margin-top: 48px

	.file
		display: flex
		align-items: center
		gap: 24px
		margin-bottom: 16px

		p
			min-width: 340px
			color: $c-button-primary

		.button_delete
			width: 32px
			height: 32px
			padding: 4px
			border-radius: 8px
			color: $c-button-primary
			background: $c-button-ghost

	.upload
		display: flex
		align-items: center
		column-gap: 11px
		width: fit-content
		padding: 10px 12px
		border-radius: 8px
		border: 2px solid $c-border
		color: $c-button-primary
		@include text-14

		&:hover
			cursor: pointer

	.control
		margin-bottom: 24px

		&_datepicker
			width: 400px
			margin-top: 48px

		&_stretch
			width: 100%

	&__footer
		display: flex
		flex-direction: column
		align-items: flex-end

		&__controls
			display: flex
			gap: 20px
