@import 'app/styles/mixins'

.form
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: calc(100% - 46px)
	margin-top: 24px

	&__field
		width: 100%

		&__upload
			display: flex
			align-items: center
			column-gap: 11px
			width: fit-content
			padding: 10px 12px
			border-radius: 8px
			border: 2px solid $c-border
			color: $c-button-primary
			@include text-14

			&:hover
				cursor: pointer

	&__row
		display: flex
		align-items: flex-start
		flex-direction: row
		flex-wrap: nowrap
		gap: 16px
		margin: 48px 0 40px

		.control
			width: 400px
			margin-top: 24px

	&__footer
		display: flex
		flex-direction: column
		align-items: flex-end

		&__controls
			display: flex
			gap: 20px

	.file
		display: flex
		justify-content: space-between
		align-items: center
		max-width: 400px
		margin-bottom: 16px
		color: $c-button-primary

		.button
			&_delete
				width: 32px
				height: 32px
				padding: 4px
				background: $c-button-ghost
				border-radius: 8px
				transition: 300ms

				&:hover
					background: $c-button-primary
					color: $c-button-ghost
