@import 'app/styles/mixins'

.check
	@include text-13
	position: relative
	display: flex
	align-items: center
	width: fit-content
	color: $c-gray
	cursor: pointer

	&:hover
		color: $c-button-primary

	&__input
		position: absolute
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none

		&:checked + .check__box
			border: none
			background-color: $c-button-primary

			&:after
				display: flex
				justify-content: center
				margin-bottom: 5px
				width: 14px
				height: 14px
				content: url('/svg-icons/check.svg')

			&:hover
				transition: 0.3s
				background: $c-button-primary

		&:focus + .check__box
			border-color: $c-button-primary

	&__box
		display: flex
		justify-content: center
		align-items: center
		width: 20px
		min-width: 20px
		height: 20px
		border-radius: 6px
		border: 1px solid $c-button-primary
		transition: 0.3s

		&_circle
			width: 24px
			min-width: 24px
			height: 24px
			border-radius: 50%

		&_with_content
			margin-right: 10px

		&_disabled
			border: 1px solid transparent
			background: #eaebee

		&:hover
			border: 1px solid $c-button-primary
