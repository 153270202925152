@import 'app/styles/mixins'

.container
	position: relative
	display: flex
	height: calc(100vh - 64px)
	filter: drop-shadow(-2px 0px 32px rgba(black, 0.16))

	.content
		width: 100%
		padding: 16px
		background: white
		overflow: auto

.close
	position: absolute
	left: -40px
	height: 40px
	width: 40px
	padding: 8px
	color: $c-button-primary
	background: white
	border-radius: 10px 0px 0px 10px

