@import 'app/styles/mixins'

.container
	padding: 2px 7px
	border-radius: 4px
	color: white
	line-height: 19px
	@include text-16

	&__wide
		padding: 2px 0

	&_active
		outline: 2px solid rgba(black, 0.2)
