@import "app/styles/mixins"

.wrapper
	width: 100%

	.header
		display: flex
		justify-content: flex-end
		align-items: center

	.container
		display: flex
		flex-wrap: wrap
		gap: 20px
		width: 100%

		&_empty
			display: flex
			justify-content: center
			align-items: center
			width: 100%
			height: calc(100vh - 149px)

		.card
			position: relative
			display: flex
			flex-direction: column
			width: 220px
			height: 410px
			padding: 16px
			background-color: white
			border-radius: 16px
			text-align: left
			overflow: hidden

			&:hover
				outline: 3px solid $c-error

			.cover
				width: 188px
				height: 200px
				object-fit: cover
				border-radius: 8px

			.title
				margin: 12px 0 4px

			.text
				margin-top: 12px
				@include text-14
