@import 'app/styles/mixins'

.content
	width: 340px
	position: relative
	padding: 16px 12px 12px
	color: black

	.close
		position: absolute
		top: 4px
		right: 4px
		padding: 4px

	.add
		color: $c-button-primary

	.switcher
		&__types
			width: fit-content
			padding: 2px
			border-radius: 4px
			background: $c-button-ghost

			.btn
				padding: 4px 8px
				border-radius: 2px
				@include text-14

				&_active
					color: white
					background: $c-gray

		&__grades
			display: flex
			column-gap: 4px

	.form
		margin-top: 12px
		text-align: left

		.select
			&:not(:first-child)
				margin-top: 12px

		.grades
			margin-top: 16px

			.label
				color: $c-gray

			&__list
				display: flex
				gap: 4px
				margin-top: 8px

		.textarea
			margin-top: 16px

		.delimiter
			margin: 16px -12px 12px
			background: $c-border

		&__footer
			display: flex
			column-gap: 8px
			justify-content: right
