@import 'app/styles/mixins'

.content
	width: 100%

	&_empty
		display: flex
		justify-content: center
		align-items: center
		min-height: calc(100vh - 193px)

	.tooltip
		position: fixed
		top: 0
		left: 0
		right: 0
		display: flex
		justify-content: space-between
		width: 100%
		min-width: 1400px
		padding: 12px 24px
		background: $c-button-primary
		box-shadow: 0px 9px 24px rgba(black, 0.25)
		color: white
		z-index: 2

		&__caption
			display: flex
			align-items: center
			gap: 62px

			p
				font-weight: 700

	.checkbox
		&_all
			margin-bottom: 8px
			margin-left: 13px

	.button
		&_clear
			color: white

		&_finish
			background: $c-button-ghost

		&_add
			margin-bottom: 18px
			background: rgba(white, 0.5)
			color: $c-gray

		&_load
			margin: 4px 0 0

	.task
		display: flex
		justify-content: space-between
		align-items: stretch
		gap: 16px
		margin-top: 2px
		padding: 16px 12px
		border: 1px solid $c-border
		border-radius: 8px
		background: white

		.check
			align-self: flex-start

		&__content
			width: 100%

		&__name
			@include text-14
			margin-bottom: 16px

		&__info
			display: flex
			justify-content: space-between
			align-items: center
			gap: 56px

			.item
				@include text-14

				&__label
					margin-bottom: 2px
					color: $c-gray

				&__data
					display: flex
					align-items: center
					gap: 8px

					.avatar
						width: 24px
						height: 24px
						border-radius: 50%
						object-fit: cover

			.deadline
				display: flex
				gap: 56px

		&__controls
			width: 195px
			padding-left: 16px
			border-left: 1px solid #d9d9d9

			&__date
				@include text-16
				color: $c-gray

			.remove_button
				margin-top: 16px
